<template>
    <div>
        <DataTable
            :value="forms"
            :paginator="true"
            lazy
            class="p-datatable-customers p-datatable-striped form-list"
            :class="{ showFilters: showFilters }"
            :rows="pageSize"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            :page-link-size='5'
            dataKey="id"
            selectionMode="single"
            :filters="filters"
            :scrollable="true"
            scroll-height="calc(100vh - 400px)"
            @row-dblclick="rowClicked"
            @filter="selectedFilter"
            @sort="onSort"
            @page="onPage"
            resizable-columns
        >
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-8 p-p-0 p-d-flex p-ai-center">
                        <h5 class="p-d-inline p-mb-0 p-pt-0">Формы</h5>
                    </div>
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-jc-end table-global-search">
                        <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                            <span class="p-float-label">
                                <InputText
                                    type="text"
                                    v-model="filters.name"
                                    placeholder="Поиск"
                                    @input='selectedFilter'
                                />
                            </span>
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-search"></i>
                            </span>
                        </div>
                        <div v-if='canEditForm' class="p-p-0 p-new-btn">
                            <Button @click="$router.push('/forms/new')" class="p-button">Добавить</Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty>Формы не найдены</template>
            <template #loading>Загружается список форм. Пожалуйста подождите</template>
            <Column
                header="Наименование"
                field="name"
                :sortable="true"
                header-class="header-name"
                body-class="body-name"
                filter-match-mode='contains'
            >
                <template #body="slotProps">
                    <div :title="slotProps.data.name" class="p-text-nowrap p-text-truncate">{{ slotProps.data.name }}</div>
                </template>
                <template v-if="showFilters" #filter>
                    <InputText
                        v-show="showFilters"
                        type="text"
                        v-model="filters.name"
                        class="p-column-filter p-my-2"
                        placeholder="Поиск"
                        @input='selectedFilter'
                    />
                </template>
            </Column>
            <Column header="Тип" field="type" :sortable="true" header-class="header-type" body-class="body-type">
                <template #body="slotProps">
                    {{ formTypes[slotProps.data.type] }}
                </template>
                <template v-if="showFilters" #filter>
                    <Dropdown
                        v-model='filters.type'
                        @input='selectedFilter'
                        :options="formTypesFilters"
                        option-value='value'
                        option-label='label'
                        placeholder="Поиск"
                        class="p-column-filter p-my-2"
                        :showClear="true"
                    />
                </template>
            </Column>
            <Column header="Периодичность" field="distributionInterval.name" :sortable="true" header-class="header-periods" body-class="body-periods">
                <template #body="slotProps">
                    {{ slotProps.data.distributionInterval.name.toLowerCase() }}
                </template>
                <template v-if="showFilters" #filter>
                    <Dropdown
                        v-model="filters.distributionInterval"
                        data-key="id"
                        :options="formPeriodsFilters"
                        option-label='name'
                        option-value='id'
                        placeholder="Поиск"
                        class="p-column-filter p-my-2"
                        :showClear="true"
                        @click.stop
                        @input='selectedFilter'
                    >
                        <template #option="slotProps">
                            <span :class="'customer-badge status-' + slotProps.option.name">{{ slotProps.option.name }}</span>
                        </template>
                    </Dropdown>
                </template>
            </Column>
            <Column header="Статус" field="status" header-class="header-status">
                <template #body="slotProps">
                    <span :style="`background: ${statusColors[slotProps.data.status]}; padding: 4px; border-radius: 4px;`">
                        {{ slotProps.data.status }}
                    </span>
                </template>
                <template v-if="showFilters" #filter>
                    <MultiSelect26
                        v-show="showFilters"
                        v-model="filters.status"
                        :options="formStatuses"
                        :showToggleAll="false"
                        data-key="label"
                        option-value="value"
                        placeholder="Поиск"
                        option-label="label"
                        display="chip"
                        class="multiselect-custom"
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column header="" header-class="p-text-center" body-style="position: relative; width: 52px; text-align: center;" header-style="width: 52px;">
                <template v-if="accessibleItems(listMenuItemsComputed).length" #body="slotProps">
                    <div v-if="slotProps.data.menuActive" class="p-panel-header-icon p-link" @click.stop="toggleRowMenu($event, slotProps)">
                        <span class="pi pi-ellipsis-h"></span>
                    </div>
                    <Menu
                        class="redLastListElement"
                        :ref="`listMenu${slotProps.data.id}`"
                        :model="accessibleItems(listMenuItemsComputed)"
                        :popup="true"
                        :baseZIndex="10"
                        :class='getDisabledClass(slotProps.data.status)'
                    />
                </template>
                <template #header>
                    <Button icon="pi pi-filter" :class="filterClasses" class="p-button-rounded p-button-outlined filter-btn" @click="showFilters = !showFilters" />
                </template>
            </Column>
            <template #paginatorLeft>
                <Button @click="downloadXLSX" label="Скачать XLSX" class="p-button p-component " />
            </template>
        </DataTable>
    </div>
</template>

<script>
import {
    downloadForms,
    editFormParametrs,
    getAllForms,
} from '@/api/form';
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { jsonApiListParser } from '@/main/utils/common';
import {
    FORM_STATUSES_COLORS,
    FORM_STATUSES_NAMES,
    FORM_TYPES,
    ASSIGNMENT_DISTRIBUTION_MODES,
    STATUSES_FORMS
} from '@/constants/forms';
import { getAllFormsPeriods } from '@/api/form/formsPeriods';
import SubmitJsonApi from '@/models/SubmitJsonApi';
import { SHOW_SEND_FORM_EVENT, USER_PERMISSIONS_MAP, FORM_PERMISSIONS_MAP, DEFAULT_PAGE_SIZE } from '@/constants/common';

const { ACTIVE, ARCHIVE, DRAFT } = FORM_STATUSES_NAMES;
const { DISTRIBUTION, ASSIGNMENT } = ASSIGNMENT_DISTRIBUTION_MODES

const {
    formVerificationRules,
    formWrite,
    formWriteOwn
} = USER_PERMISSIONS_MAP

const { distribute } = FORM_PERMISSIONS_MAP

export default {
    name: 'formsList',
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    emits: ['loadingChange'],
    mixins: [requestToastHandler],
    data() {
        this.statusColors = FORM_STATUSES_COLORS;
        this.formStatuses = STATUSES_FORMS;
        this.formTypesFilters = Object.values(FORM_TYPES);
        this.formTypes = {}
        Object.values(FORM_TYPES).forEach(i => {
            this.formTypes[i.value] = i.label
        })
        this.pageSize = DEFAULT_PAGE_SIZE;
        return {
            notifications: null,
            orgsForSendEnd: 50,
            orgsForSendPerPage: 50,
            orgsForSendCount: 1,
            notParsedOrgs: [],
            allOrgs: [],
            loadingOrgs: false,
            assignments: [],
            searchInputOrgs: '',
            formPeriodsFilters: [],
            editItemId: null,
            filters: { status: ['active'] },
            showFilters: false,
            forms: [],
            totalRecords: null,
            sortField: '',
            filterTimeout: null,
            currentPage: 1,
            canDistribute: true
        };
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'userPermissionsObject',
            'formPermissionsObject',
            'accessibleItems'
        ]),
        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
        selectedForm() {
            return this.forms.find((item) => item.id === this.editItemId) || { id: '' }
        },
        canEditForm() {
            return [formWrite].some(p => this.userPermissionsObject[p])
        },
        notActiveSelectedForm() {
            return this.selectedForm.status !== ACTIVE
        },
        listMenuItemsComputed() {
            return [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editItem();
                    },
                    permissionAccess: [formWrite]
                },
                {
                    label: 'Раздать форму',
                    icon: 'pi pi-angle-double-down',
                    class: 'send-form',
                    command: () => {
                        this.openDialogHandler({ mode: DISTRIBUTION, form: this.selectedForm, filters: {} });
                    },
                    visible: this.canDistribute,
                    permissionAccess: [
                        formWrite,
                        formWriteOwn,
                        formVerificationRules
                    ]
                },
                {
                    label: 'Назначить форму',
                    icon: 'pi pi-angle-double-down',
                    class: 'send-form',
                    command: () => {
                        this.openDialogHandler({ mode: ASSIGNMENT, form: this.selectedForm, filters: {} })
                    },
                    permissionAccess: [formWrite]
                },
                {
                    label: 'Сделать архивной',
                    icon: 'pi icon custom-check-square',
                    class: 'archive',
                    command: () => {
                        this.changeStatus(ARCHIVE)
                    },
                    permissionAccess: [formWrite]
                },
                {
                    label: 'Сделать активной',
                    icon: 'pi icon custom-check-square',
                    class: 'active',
                    command: () => {
                        this.changeStatus(ACTIVE)
                    },
                    permissionAccess: [formWrite]
                }
            ]
        }
    },
    methods: {
        getDisabledClass(itemStatus) {
            const classMap = {
                [ACTIVE]: 'disabled-active',
                [DRAFT]: 'disabled-draft',
                [ARCHIVE]: 'disabled-archive'
            }
            return classMap[itemStatus]
        },
        async changeStatus(newStatus) {
            if (this.selectedForm.status === newStatus) {
                return
            }
            const statusFieldsMap = {
                [ARCHIVE]: { archive: true, active: false },
                [ACTIVE]: { archive: false, active: true },
                [DRAFT]: { archive: false, active: false }
            }
            const distInterval = {
                id: this.selectedForm.distributionInterval.id,
                type: 'form-distribution-interval'
            }
            const data = {
                ...this.selectedForm,
                ...statusFieldsMap[newStatus],
                id: this.editItemId,
                distributionInterval: distInterval
            }
            try {
                this.$emit('loadingChange', true)
                await editFormParametrs(this.editItemId, new SubmitJsonApi(data, 'form'))
                const formIdx = this.forms.findIndex(({ id }) => id === this.editItemId)
                this.$set(this.forms, formIdx, { ...this.selectedForm, ...statusFieldsMap[newStatus], status: newStatus })
            } catch (error) {
                this.$requestError(error.message)
            } finally {
                this.$emit('loadingChange', false)
            }
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        async openDialogHandler(payload) {
            if (this.selectedForm.status !== ACTIVE) return
            this.$root.$emit(SHOW_SEND_FORM_EVENT, payload)
        },
        editItem() {
            if (!this.canEditForm) {
                return;
            }
            this.$router.push(`/forms/edit/${ this.editItemId }`);
        },
        rowClicked({ data }) {
            if (!this.canEditForm) return;
            this.editItemId = data.id;
            this.editItem();
        },
        toggleRowMenu(event, slotProps) {
            const {
                data: { id },
            } = slotProps;

            if (id !== this.editItemId) {
                this.$refs[`listMenu${this.editItemId}`] && this.$refs[`listMenu${this.editItemId}`].hide(event);
            }
            this.editItemId = id;

            if (this.formPermissionsObject[id] && !this.formPermissionsObject[id][distribute]) {
                this.canDistribute = false;
            }
            if (Object.keys(this.formPermissionsObject).length > 0
                && !this.formPermissionsObject[id]) {
                this.canDistribute = false;
            }
            if ([ formWriteOwn, formVerificationRules ].some(p => this.userPermissionsObject[p])
                && Object.keys(this.formPermissionsObject).length === 0) {
                this.canDistribute = false;
            }
            if ([ formWrite ].some(p => this.userPermissionsObject[p])) {
                this.canDistribute = true;
            }

            this.$refs[`listMenu${id}`].toggle(event);
        },
        async getAllForms() {
            let filter = {};

            if (this.filters.name && this.filters.name.length > 0) {
                filter['name'] = { $ilike: this.filters.name };
            }

            if (this.filters.type && this.filters.type.length > 0) {
                filter['type'] = { $eq: this.filters.type };
            }

            if (this.filters.distributionInterval && this.filters.distributionInterval.length > 0) {
                filter['distributionInterval.id'] = { $eq: this.filters.distributionInterval };
            }
            if (this.filters.status && this.filters.status.length > 0) {
                let statusFilter = [];
                if (this.filters.status.includes('active')) {
                    statusFilter.push({ active: { $eq: true }, archive: { $eq: false } });
                }
                if (this.filters.status.includes('archive')) {
                    statusFilter.push({ active: { $eq: false }, archive: { $eq: true } });
                }
                if (this.filters.status.includes('draft')) {
                    statusFilter.push({ active: { $eq: false }, archive: { $eq: false } });
                }

                if (Object.keys(filter).length > 0) {
                    let allFilter = {
                        $and: [
                            filter,
                            {
                                $or: statusFilter
                            }
                        ]
                    };
                    filter = allFilter;
                } else {
                    filter = {
                        $or: statusFilter
                    };
                }
            }
            try {
                this.$emit('loadingChange', true);
                const { forms, included, meta } = await getAllForms({
                    page: this.currentPage,
                    pageSize: this.pageSize,
                    relationShip: true,
                    filter,
                    sort: this.sortField
                });
                this.forms = jsonApiListParser(forms, included);
                this.forms = this.forms.map((form) => {
                    form.status = form.archive ? ARCHIVE : DRAFT;
                    if (form.active) form.status = ACTIVE;
                    if (form.active && form.archive) form.status = null;
                    form.menuActive = true;
                    if (this.formPermissionsObject[form.id] && !this.formPermissionsObject[form.id][distribute]) {
                        form.menuActive = false;
                    }
                    if (Object.keys(this.formPermissionsObject).length > 0
                        && !this.formPermissionsObject[form.id]) {
                        form.menuActive = false;
                    }
                    if ([ formWriteOwn, formVerificationRules ].some(p => this.userPermissionsObject[p])
                        && Object.keys(this.formPermissionsObject).length === 0) {
                        form.menuActive = false;
                    }
                    if ([ formWrite ].some(p => this.userPermissionsObject[p])) {
                        form.menuActive = true;
                    }
                    return form;
                });
                this.totalRecords = meta.pagination.total;
                const { allPeriods, included: periodsIncluded } = await getAllFormsPeriods();
                this.formPeriodsFilters = jsonApiListParser(allPeriods, periodsIncluded);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        onSort({ sortField, sortOrder }) {
            this.sortField = `${ sortOrder > 0 ? '' : '-' }${ sortField }`;
            this.debouncedFilter();
        },

        onPage({ page, rows }) {
            this.pageSize = rows;
            this.currentPage = page + 1;
            this.debouncedFilter();
        },

        selectedFilter() {
            this.currentPage = 1;
            this.pageSize = DEFAULT_PAGE_SIZE;
            this.debouncedFilter();
        },

        debouncedFilter() {
            clearTimeout(this.filterTimeout);

            this.filterTimeout = setTimeout(async () => {
                await this.getAllForms();
            }, 1000);
        },

        downloadXLSX() {
            let filter = {};

            if (this.filters.name && this.filters.name.length > 0) {
                filter['name'] = { $ilike: this.filters.name };
            }

            if (this.filters.type && this.filters.type.length > 0) {
                filter['type'] = { $eq: this.filters.type };
            }

            if (this.filters.distributionInterval && this.filters.distributionInterval.length > 0) {
                filter['distributionInterval.id'] = { $eq: this.filters.distributionInterval };
            }

            if (this.filters.status && this.filters.status.length > 0) {
                if (this.filters.status.includes('active')) {
                    if (this.filters.status.length > 1) {
                        if (Object.keys(filter).length > 0) {
                            let statusFilter = {
                                $and: [
                                    filter,
                                    {
                                        $or: [
                                            {
                                                active: {$eq: true},
                                                archive: {$eq: false}
                                            },
                                            {
                                                active: {$eq: false},
                                                archive: {$eq: true}
                                            },
                                        ]
                                    }
                                ]
                            };
                            filter = statusFilter;
                        } else {
                            filter = {
                                $or: [
                                    {
                                        active: {$eq: true},
                                        archive: {$eq: false}
                                    },
                                    {
                                        active: {$eq: false},
                                        archive: {$eq: true}
                                    },
                                ]
                            };
                        }
                    } else {
                        filter['archive'] = {$eq: false};
                        filter['active'] = {$eq: true};
                    }
                }
                if (this.filters.status.includes('archive') && this.filters.status.length === 1) {
                    filter['archive'] = {$eq: true};
                    filter['active'] = {$eq: false};
                }
            }

            downloadForms({ filter: filter });
        },
    },
    watch: {
        searchInputOrgs: {
            handler(to) {
                this.updateOrgList(to)
            }
        }
    },

    async created() {
        await this.getAllForms();
    },
};
// 556
</script>

<style lang="scss" scoped>
.layout-wrapper.layout-static-inactive .layout-notification {
    margin-left: 0;
}

.layout-notification {
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 1rem 3rem;
    margin-bottom: 6px;

    &-content {
        display: flex;
        flex: 1;
    }

    &-close {
        line-height: 1.5;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        transition: background-color .3s;
    }

    &-close:hover {
        background-color: hsla(0,0%,100%,.2);
    }
}

::v-deep {
    .disabled {
        &-draft {
            .draft, .send-form {
                a {
                    opacity: .6;
                }
            }
        }
        &-archive {
            .archive, .send-form {
                a {
                    opacity: .6;
                }
            }
        }
        &-active {
            .active {
                a {
                    opacity: .6;
                }
            }
        }
    }
    .p-field {
        .p-dropdown {
            &.p-disabled {
                background: #F5F5F5;
            }
        }
    }
    .p-multiselect-token {
        background: var(--primary-color);
        height: 24px;
        width: 120px;
        padding: 4px 8px;
        border-radius: 3px;
        color: white;
        &-label {
            max-width: 88px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .form-list {
        &-dialog {
            width: 960px!important;
            .p-dialog {
                &-header {
                    padding: 24px 24px 0 24px;
                    font-size: 18px;
                }
                &-content {
                    padding: 24px 24px 0 24px;
                }
                &-footer {
                    padding: 24px 24px 24px 24px;
                }
            }
            .p-button {
                height: 34px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        ::v-deep {
            $col-padding-right: 3.06640625vw;
            .header,
            .body {
                &-name {
                    width: calc(34.21875vw + #{$col-padding-right});
                }
                &-type {
                    width: calc(9.375vw + #{$col-padding-right});
                }
                &-periods {
                    width: calc(10.28125vw + #{$col-padding-right});
                }
            }

            tbody,
            thead {
                tr {
                    td,
                    th {
                        &:not(:first-child) {
                            padding-left: 0 !important;
                        }
                        &:not(:last-child) {
                            padding-right: $col-padding-right !important;
                        }
                    }
                }
            }
        }
    }
}
.radio-btns-container {
    .p-field-radiobutton {
        margin-bottom: 5px!important;
    }
    label {
        margin-bottom: unset!important;
        line-height: 1rem;
    }
}

::v-deep .p-message {
    display: flex;

    .p-message-icon {
        display: none;
    }

    .p-message-text {
        width: 98%;
    }

    .p-message-wrapper {
        align-items: normal;
        position: relative;
        width: 100%;
    }

    .p-message-close {
        width: 28px;
        position: absolute;
        top: 8px;
        right: 8px;
    }
}

::v-deep .multiselect-custom {
    min-width: 98%;
    max-width: 98%;
    margin-top: 4px;

    .p-multiselect-label {
        color: #8C8C8C;
        font-size: 13px;
        grid-template-columns: none;
    }

    .p-multiselect-token {
        align-items: center;
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: 0.5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);
        .p-multiselect-token-label {
            margin-right: 0.25rem;
            white-space: normal;
        }
    }

    .p-multiselect-trigger {
        width: 1.3rem;
    }

    .p-multiselect-item {
        width: 340px;
        min-width: 100%;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
        right: 1.2rem;
    }
}
</style>
